import React from "react";
import Layout from '../components/layout';
import { graphql, Link } from 'gatsby';
import slugify from 'slugify';

export default function Stories({data}) {
    return (
        <Layout title="Korte verhalen">
            <h1>Korte verhalen</h1>
            <p>Op deze pagina hou ik korte verhalen bij die ik schrijf voor een cursus. Wil je reageren? Gebruik het <Link to="/contact">contactformulier</Link> of &eacute;&eacute;n van de Social Media-links op de <Link to="/">homepagina</Link>.</p>
                <ul>
                    {data.allContentfulStory.nodes.map( ( node, index ) => (
                        <li key={index}>
                            <Link to={`/stories/${slugify(node.title)}`}>{node.title}</Link>
                        </li>
                    ))}
                </ul>
        </Layout>
    );
};
export const query = graphql`
    query {
        allContentfulStory {
            nodes {
                summary {
                    summary
                }
                title
            }
        }
    }
`;